var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"myTitle",rawName:"v-myTitle"}],attrs:{"data-title":_vm.textTitle}},[_c('div',{staticClass:"exam"},[(_vm.type == 2)?_c('div',{staticStyle:{"text-align":"left","font-size":"14px","margin-left":"20px"}},[_vm._v(" 被评者："+_vm._s(_vm.staff.name)+" ")]):_vm._e(),_vm._l((_vm.examList),function(items,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nowIndex == index),expression:"nowIndex == index"}],key:index,staticClass:"examLists"},[_c('div',{staticClass:"nobone"},[_vm._v("第"+_vm._s(index + 1)+"题："+_vm._s(items.content))])])}),_c('div',{staticClass:"question"},_vm._l((_vm.questList),function(item,index){return _c('div',{key:index,class:['selection', _vm.currentIndex == index ? 'selected' : ''],on:{"click":function($event){return _vm.selected(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),(
      (_vm.examList[_vm.nowIndex] &&
        _vm.type == 1 &&
        _vm.examList[_vm.nowIndex].level_show == 3) ||
        (_vm.examList[_vm.nowIndex] &&
          _vm.type == 2 &&
          _vm.examList[_vm.nowIndex].level_show != 1)
    )?_c('div',{staticClass:"levelCont"},[_c('div',{staticClass:"nobone"},[_vm._v("问题重要性")]),_c('div',{staticClass:"question"},_vm._l((_vm.levelList),function(item,index){return _c('div',{key:index,class:['selection', 'red', _vm.questionIndex == index ? 'quested' : ''],on:{"click":function($event){return _vm.quested(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"change"},[(_vm.nowIndex != 0)?_c('button',{staticClass:"last",on:{"click":_vm.lastEvent}},[_vm._v(" 上一题 ")]):_vm._e(),(_vm.nowIndex < _vm.examList.length - 1)?_c('button',{staticClass:"last",on:{"click":_vm.nextEvent}},[_vm._v(" 下一题 ")]):_vm._e(),(_vm.nowIndex == _vm.examList.length - 1)?_c('button',{staticClass:"last",on:{"click":_vm.doneover}},[_vm._v(" 完成评分 ")]):_vm._e()])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }